import * as React from 'react';
import {array, bool, func, number, object, string} from "prop-types";
import {Box, Button, IconButton, Input, Paper, Switch, Tooltip, Typography} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import {formatPrice} from 'assets/utils';
import {connectProps, Plugin, Template, TemplateConnector, TemplatePlaceholder} from '@devexpress/dx-react-core';
import {
    DataTypeProvider,
    FilteringState,
    GroupingState,
    IntegratedFiltering,
    IntegratedGrouping,
    IntegratedSelection,
    IntegratedSorting,
    IntegratedSummary,
    RowDetailState,
    SearchState,
    SelectionState,
    SortingState,
    SummaryState
} from '@devexpress/dx-react-grid';
import {Grid, Table, TableFilterRow, TableGroupRow, TableHeaderRow, TableRowDetail, TableSelection, TableSummaryRow, Toolbar, VirtualTable, GroupingPanel} from '@devexpress/dx-react-grid-material-ui';
import './reactGrid.styles.scss';
import DeleteIcon from '@material-ui/icons/ClearRounded';
import isNumber from "moment/src/lib/utils/is-number";

const localization = {
    Table: {
        noData: 'No hay datos para mostrar'
    },
    TableFilterRow: {
        filterPlaceholder: 'Filtrar',
        contains: 'Contiene',
        notContains: 'No Contiene',
        startsWith: 'Comienza con',
        endsWith: 'Termina con',
        equal: 'Es igual a',
        notEqual: 'Es distinto de',
        greaterThan: 'Es mayor a',
        greaterThanOrEqual: 'Es mayor o igual a',
        lessThan: 'Es menor a',
        lessThanOrEqual: 'Es menor o igual a'
    },
    SearchPanel: {
        searchPlaceholder: 'Buscar...'
    },
    TableHeaderRow: {
        sortingHint: 'Ordenar'
    }
};

function CustomToolbar(props) {
    const {
        rows, title, enableFiltering, showSwitchAll, disableSwitchAll, switchAllValue, onChangeSwitchAll, toggleFilterPanel, showAddButton, addButtonDisabled,
        onClickAddButton, enableClearSelection, selectedRows, onDelete, customBtn1, customBtn2, showResultCount
    } = props;

    return (
        <Plugin name="FilterToggle">
            <Template name="toolbarContent">
                {(enableClearSelection && selectedRows.length > 0) && (
                    <Tooltip title="Cancelar selección">
                        <Box>
                            <IconButton aria-label="Delete" onClick={() => onDelete(selectedRows)} edge={"start"}>
                                <DeleteIcon/>
                            </IconButton>
                        </Box>
                    </Tooltip>
                )}

                {title !== '' && (
                    <Typography variant='body2' color='textSecondary'>
                        {title}
                        {
                            ((showSwitchAll && switchAllValue === false) || selectedRows.length > 0) ?
                                (` (${selectedRows.length} seleccionados)`) :
                                showResultCount ?
                                    (` (${rows.length})`) : ''
                        }
                    </Typography>
                )}

                <TemplatePlaceholder/>

                {enableFiltering && (!showSwitchAll || !switchAllValue) && (
                    <Tooltip title={'Filtrar'}>
                        <IconButton aria-label="Filter" onClick={toggleFilterPanel}>
                            <FilterListIcon/>
                        </IconButton>
                    </Tooltip>
                )}

                {customBtn1}
                {customBtn2}

                {showSwitchAll && selectedRows.length <= 0 && (
                    <div className={'ml-2'}>
                        <Switch checked={switchAllValue} disabled={disableSwitchAll} onChange={onChangeSwitchAll}/>
                        <Typography
                            variant={"caption"}
                            onClick={onChangeSwitchAll}
                            style={{cursor: 'pointer'}}
                            color={switchAllValue ? 'textPrimary' : 'textSecondary'}>todos</Typography>
                    </div>
                )}

                {showAddButton && selectedRows.length <= 0 && (
                    <Tooltip title="Agregar item">
                        <Box className={'ml-5'}>
                            <Button
                                variant="outlined"
                                size='small'
                                color='primary'
                                onClick={onClickAddButton}
                                disabled={addButtonDisabled}
                            >
                                Agregar
                            </Button>
                        </Box>
                    </Tooltip>
                )}
            </Template>
        </Plugin>
    );
}

const TableRow = ({row, rowClick, onRowClick, rowExtensions, tableRow, selectByRowClick, ...restProps}) => {
    row = row ? row : tableRow.row;

    delete restProps.highlighted;

    return (
        <Table.Row
            {...restProps}
            onClick={() => {
                if (rowClick)
                    onRowClick(row);
                if (selectByRowClick)
                    restProps.onToggle();
            }}
            style={{
                cursor: rowClick || selectByRowClick ? 'pointer' : 'default',
                backgroundColor: rowExtensions.color ? rowExtensions.color(row) : ''
            }}
        />
    )
};

const Cell = ({onEdit, preventEditRow, columnExtensions, ...restProps}) => {
    const {column} = restProps;

    if (column.name === 'edit') {
        return (
            <Table.Cell
                {...restProps}
                style={{padding: '0'}}
            >
                {(preventEditRow === null || preventEditRow(restProps.row) === false) && (
                    <Tooltip title={'Editar'} placement="left">
                        <IconButton
                            aria-label="Edit"
                            onClick={(e) => {
                                e.stopPropagation();
                                onEdit(restProps.row, restProps.tableRow.rowId);
                            }}>
                            <EditIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                )}
            </Table.Cell>
        );
    }

    if (column.name.startsWith('custom')) {
        const columnExtension = columnExtensions.find(columnExtension => columnExtension.columnName === column.name);
        const Component = columnExtension.component;
        return (
            <Table.Cell
                {...restProps}
                style={{padding: '0'}}
            >
                <Component row={restProps.tableRow.row} rowId={restProps.rowId}/>
            </Table.Cell>
        );
    }

    return <Table.Cell {...restProps} style={column.style || {}}/>;
};

const TableSelectionHeaderCell = ({onSelectionChange, getRowId, restProps}) => {
    return (
        getRowId &&
        <TemplateConnector>
            {({rows: filteredRows, selection: selectedRows}) => {
                let allSelectedProp = false;
                if (filteredRows.map(getRowId).every(row => selectedRows.includes(row)))
                    allSelectedProp = true;

                return (
                    <TableSelection.HeaderCell
                        onToggle={() => {
                            const rowsToSelect = allSelectedProp ? [] : filteredRows.map(getRowId);
                            onSelectionChange(rowsToSelect);
                        }}
                        allSelected={allSelectedProp}
                        {...restProps}
                    />
                )
            }}
        </TemplateConnector>
    )
};

const CustomFilterComponent = ({onFilterRows}) =>
    <TemplateConnector>
        {({rows: filteredRows}) => {
            onFilterRows(filteredRows);
            return <></>
        }}
    </TemplateConnector>

const FilterButton = ({type, ...restProps}) => {
    return <TableFilterRow.ToggleButton style={{marginLeft: '0px', marginRight: '-5px', padding: '5px'}} type={type} {...restProps} />;
};

const FilterIcon = ({type, ...restProps}) => {
    return <TableFilterRow.Icon style={{fontSize: '1.4rem'}} type={type} {...restProps} />;
};

const CurrencyFormatter = (
    ({value, classes}) => `${formatPrice(value, true)}`
);

const CurrencyTypeProvider = (props) => (
    <DataTypeProvider
        formatterComponent={CurrencyFormatter}
        {...props}
    />
);

// Se usa para el che de pedidos. Quitar.
const CheckColumnTypeFormatter = (
    ({value, classes}) => value ? <Tooltip title={""}><CheckIcon color={"primary"}/></Tooltip> : ''
);

const CheckColumnTypeProvider =
    (props) => (
        <DataTypeProvider
            formatterComponent={CheckColumnTypeFormatter}
            {...props}
        />
    );

// Test Custom Searching
// NOTE: custom plugin instead SearchPanel
const CustomSearchPanel = ({onValueChange}) => (
    <Plugin name="CustomSearchPanel">
        <Template name="toolbarContent">
            <TemplatePlaceholder/>
            <Input
                placeholder="Buscar"
                onChange={e => onValueChange(e.currentTarget.value)}
            />
        </Template>
    </Plugin>
);

export class ReactGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            columns: props.columns,
            columnExtensions: props.columnExtensions,
            rows: props.rows,
            rowExtensions: props.rowExtensions,
            showFilters: props.showFilters,
            searchValue: props.searchValue
        };

        this.row = connectProps(TableRow, () => ({
            rowClick: props.rowClick,
            onRowClick: props.onRowClick,
            rowExtensions: props.rowExtensions
        }));

        this.cell = connectProps(Cell, () => ({
            onEdit: props.onEdit,
            preventEditRow: props.preventEditRow,
            columnExtensions: props.columnExtensions
        }));

        this.headerCell = connectProps(TableSelectionHeaderCell, () => ({
            onSelectionChange: props.onSelectionChange,
            getRowId: props.getRowId,
            selectedRows: props.selectedRows
        }));

        // Si el usuario eligió la opción edition agrego la columna
        if (this.props.enableEdition) {
            this.state.columns.push({name: 'edit', title: ' '});
            this.state.columnExtensions.push({columnName: 'edit', sortingEnabled: false, width: 70});
        }
    }

    changeSearchValue = value => this.setState({searchValue: value});
    toggleFilterPanel = () => this.setState(prevState => ({showFilters: !prevState.showFilters}));

    getAdjustedHeight = height => {
        if (isNumber(height) && height < 0) {
            return window.innerHeight + height;
        }

        return height;
    }

    // Test Custom Search
    changeCustomSearchValue = value => {
        const words = value.split(" ");
        let filters = [];
        this.props.columns.forEach(column => {
                let wordFilters = [];
                words.forEach(word => {
                    wordFilters.push({
                        columnName: column.name,
                        value: word,
                        operation: "contains"
                    });
                });
                filters.push({
                    filters: wordFilters,
                    operator: 'and'
                });
            }
        );

        filters = {
            filters: filters,
            operator: 'or'
        };

        this.setState({filters: [filters]});
    };

    render() {
        const {searchValue, showFilters, filters} = this.state;
        const {
            enableVirtualMode, width, height, rows, columnExtensions, title, enableSorting, enableFiltering, enableSearch, enableSelection,
            selectedRows, onSelectionChange, selectByRowClick, showSelectAll, showSwitchAll, disableSwitchAll, getRowId, elevation, switchAllValue,
            onChangeSwitchAll, showAddButton, addButtonDisabled, onClickAddButton, enableClearSelection, enableGrouping, enableSummarizing,
            groupingExtensions, totalSummaryItemsExtensions, groupSummaryItemsExtensions, summaryMessages, groupMessages,
            onDelete, customBtn1, customBtn2, showResultCount, enableRowDetails, rowDetailsComponent, onFilterRows
        } = this.props;
        let {columns} = this.props;

        let currencyColumns = [];
        let checkColumns = [];

        // Filtro las columnas no visibles
        columns = columns.filter(column => column.visible !== false);

        if (columnExtensions) {
            currencyColumns = columnExtensions.map(column => {
                let sReturn;

                if (column.type === 'currency')
                    sReturn = column.columnName;

                return sReturn;
            });

            checkColumns = columnExtensions.map(column => {
                let sReturn;

                if (column.type === 'check')
                    sReturn = column.columnName;

                return sReturn;
            });
        }

        return (
            <div className='niquel-reactGrid'>
                <Paper elevation={elevation} style={{width: width ? width : 'auto', margin: 'auto'}}>
                    <Grid
                        rows={rows}
                        columns={columns}
                        getRowId={getRowId}
                    >
                        {/*Sorting*/}
                        {enableSorting &&
                            <SortingState defaultSorting={columnExtensions}/>
                        }

                        {enableSorting &&
                            <IntegratedSorting columnExtensions={columnExtensions}/>
                        }

                        {/*{Selection}*/}
                        {enableSelection &&
                            <SelectionState selection={selectedRows} onSelectionChange={onSelectionChange}/>
                        }

                        {enableSelection &&
                            <IntegratedSelection/>
                        }

                        {/*Searching*/}
                        {enableSearch &&
                            <SearchState
                                value={searchValue}
                                onValueChange={this.changeSearchValue}
                            />
                        }

                        {/*Filtering AND now searching*/}
                        {(enableFiltering || enableSearch) &&
                            <FilteringState defaultFilters={[]} filters={filters}/>
                        }
                        <IntegratedFiltering/>

                        {/*Grouping*/}
                        {enableGrouping &&
                            <GroupingState grouping={groupingExtensions}/>
                        }

                        {enableGrouping &&
                            <IntegratedGrouping/>
                        }

                        {/*Sumarizing*/}
                        {enableSummarizing &&
                            <SummaryState
                                totalItems={totalSummaryItemsExtensions}
                                groupItems={groupSummaryItemsExtensions}
                            />
                        }

                        {enableSummarizing &&
                            <IntegratedSummary/>
                        }

                        {enableRowDetails &&
                            <RowDetailState/>
                        }

                        <CurrencyTypeProvider for={currencyColumns}/>
                        <CheckColumnTypeProvider for={checkColumns}/>

                        {enableVirtualMode === true ? (
                            <VirtualTable
                                height={((showSwitchAll && switchAllValue) || (showAddButton && rows.length === 0)) ? 0 : this.getAdjustedHeight(height)}
                                rowComponent={this.row}
                                cellComponent={this.cell}
                                messages={localization.Table}
                                columnExtensions={columnExtensions}
                            />
                        ) : (
                            <Table
                                height={((showSwitchAll && switchAllValue) || (showAddButton && rows.length === 0)) ? 0 : this.getAdjustedHeight(height)}
                                rowComponent={this.row}
                                cellComponent={this.cell}
                                messages={localization.Table}
                                columnExtensions={columnExtensions}
                            />
                        )}

                        <TableHeaderRow showSortingControls={enableSorting} messages={localization.TableHeaderRow}/>

                        {enableGrouping &&
                            <TableGroupRow showColumnsWhenGrouped messages={groupMessages}/>
                        }

                        {enableSummarizing &&
                            <TableSummaryRow messages={summaryMessages}/>
                        }

                        {showFilters &&
                            <TableFilterRow
                                showFilterSelector
                                iconComponent={FilterIcon}
                                toggleButtonComponent={FilterButton}
                                messages={localization.TableFilterRow}
                            />
                        }

                        {(title || enableSearch || enableFiltering) &&
                            <Toolbar/>
                        }

                        {/*{enableSearch && (!showSwitchAll || !switchAllValue) && (<SearchPanel InputProps={{width: 50}} messages={localization.SearchPanel}/>)}*/}
                        {enableSearch && (!showSwitchAll || !switchAllValue) &&
                            <CustomSearchPanel onValueChange={this.changeCustomSearchValue}/>
                        }

                        {enableSelection &&
                            <TableSelection
                                rowComponent={this.row}
                                selectByRowClick={selectByRowClick}
                                showSelectAll={showSelectAll}
                                headerCellComponent={this.headerCell}
                            />
                        }

                        {enableRowDetails &&
                            <TableRowDetail contentComponent={rowDetailsComponent}/>
                        }

                        <CustomToolbar
                            rows={rows}
                            title={title}
                            enableFiltering={enableFiltering}
                            showSwitchAll={showSwitchAll}
                            disableSwitchAll={disableSwitchAll}
                            switchAllValue={switchAllValue}
                            onChangeSwitchAll={onChangeSwitchAll}
                            showAddButton={showAddButton}
                            addButtonDisabled={addButtonDisabled}
                            onClickAddButton={onClickAddButton}
                            toggleFilterPanel={this.toggleFilterPanel}
                            enableClearSelection={enableClearSelection}
                            selectedRows={selectedRows}
                            onDelete={onDelete}
                            customBtn1={customBtn1}
                            customBtn2={customBtn2}
                            showResultCount={showResultCount}
                        />

                        <CustomFilterComponent onFilterRows={onFilterRows}/>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

ReactGrid.defaultProps = {
    enableVirtualMode: true,
    width: undefined,
    height: undefined,
    rows: [],
    rowExtensions: {},
    columns: [],
    columnExtensions: [],
    title: '',
    enableSorting: true,
    enableSearch: true,
    enableSelection: false,
    enableFiltering: true,
    enableEdition: false,
    enableClearSelection: true,
    enableGrouping: false,
    enableSummarizing: false,
    enableRowDetails: false,
    showFilters: false,
    searchValue: '',
    defaultFilters: [],
    selectedRows: [],
    onSelectionChange: selected => false,
    selectByRowClick: true,
    showSelectAll: true,
    showSwitchAll: false,
    disableSwitchAll: false,
    switchAllValue: true,
    onChangeSwitchAll: () => {
    },
    getRowId: null,
    elevation: 1,
    showAddButton: false,
    addButtonDisabled: false,
    onClickAddButton: () => {
    },
    onEdit: (row, rowId) => {
    },
    preventEditRow: null,
    onDelete: selected => {
    },
    customBtn1: null,
    customBtn2: null,
    showResultCount: false,
    rowDetailsComponent: null,
    onFilterRows: () => {}
};

ReactGrid.propTypes = {
    enableVirtualMode: bool,
    width: number,
    height: number,
    rows: array,
    rowExtensions: object,
    columns: array,
    columnExtensions: array,
    title: string,
    enableSorting: bool,
    enableSearch: bool,
    enableSelection: bool,
    enableFiltering: bool,
    enableEdition: bool,
    enableClearSelection: bool,
    enableGrouping: bool,
    enableSummarizing: bool,
    enableRowDetails: bool,
    showFilters: bool,
    searchValue: string,
    defaultFilters: array,
    selectedRows: array,
    onSelectionChange: func,
    selectByRowClick: bool,
    showSelectAll: bool,
    showSwitchAll: bool,
    disableSwitchAll: bool,
    switchAllValue: bool,
    onChangeSwitchAll: func,
    getRowId: func,
    elevation: number,
    showAddButton: bool,
    addButtonDisabled: bool,
    onClickAddButton: func,
    onEdit: func,
    preventEditRow: func,
    onDelete: func,
    customBtn1: object,
    customBtn2: object,
    showResultCount: bool,
    rowDetailsComponent: object,
    onFilterRows: func
};
