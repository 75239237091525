import React, {useReducer, useState} from 'react'
import {Box, Fab} from "@material-ui/core";
import SubHeader from "components/helpers/subHeader/subHeader";
import Grid from "@material-ui/core/Grid";
import {SettingsRounded} from "@material-ui/icons";
import 'components/estadisticas/estadisticas.scss';
import VentasSettings from "components/estadisticas/settings/ventas.settings";
import {generalReducer} from "components/helpers/reducers/reducers";
import {INITIAL_FILTER_SETTINGS_STATE} from "components/helpers/initialFilterSettingsState";
import {VentasMensualesCard} from "./cards/ventas.mensuales.card";
import {RankingVendedoresCard} from "./cards/ranking.vendedores.card";
import {RankingClientesCard} from "./cards/ranking.clientes.card";
import {RankingArticulosCard} from "./cards/ranking.articulos.card";
import {CompradoresMensualesCard} from "./cards/compradores.mensuales.card";

export function EstadisticasVentas(props) {
    const [_settingsState, dispatch] = useReducer(generalReducer, INITIAL_FILTER_SETTINGS_STATE);
    const [settingsState, setSettingsState] = useState(INITIAL_FILTER_SETTINGS_STATE);
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);

    const onConfirmSettingsDialog = () => {
        setSettingsState(_settingsState);
        setSettingsDialogOpen(false);
    }
    const onCancelSettingsDialog = () => {
        dispatch({payload: settingsState, type: 'fullstatechange'});
        setSettingsDialogOpen(false);
    }

    return (
        <>
            <Box className='niquel-estadisticas-main'>
                <SubHeader
                    title={'Ventas'}
                    subtitle={'Estadísticas de ventas'}
                />

                <Box className='container mt-3 mb-3'>
                    <Box className={'mt-3'}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <VentasMensualesCard settingsState={settingsState}/>
                            </Grid>
                            <Grid item xs={12}>
                                <CompradoresMensualesCard settingsState={settingsState}/>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <RankingVendedoresCard settingsState={settingsState}/>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <RankingClientesCard settingsState={settingsState}/>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <RankingArticulosCard settingsState={settingsState}/>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className={'niquel-fab-btn'}>
                        <Fab onClick={() => setSettingsDialogOpen(true)} color={"primary"}>
                            <SettingsRounded/>
                        </Fab>
                    </Box>
                </Box>
            </Box>
            <VentasSettings
                open={settingsDialogOpen}
                title={'Filtros y confirguración'}
                onCancel={onCancelSettingsDialog}
                onConfirm={onConfirmSettingsDialog}
                fullScreen={true}
                maxWidth={'lg'}
                state={_settingsState}
                dispatch={dispatch}
            />
        </>
    )
}
