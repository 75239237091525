import React, {Component} from 'react'
import {Box} from "@material-ui/core/index";
import {FacturasOperation} from 'components/ventas/facturas/operation/facturas.operation';
import {ClientesService, EmailService, FacturasService, MastersService, VendedoresService} from "services";
import {calcImporteByArticulo} from 'assets/utils';
import MomentUtils from "@date-io/moment/build/index";
import 'moment/locale/es';
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import {ExportOptions} from "../../../helpers/exportOptions/export.options";

export class FacturasView extends Component {
    state = {
        cliente: {},
        vendedor: {},
        documents: [],
        id: this.props.match.params.factura,
        condComerciales: [],
        vendedores: [],
        tipoasientos: [],
        talfacturas: [],
        talremitos: [],
        jurisdicciones: [],
        transportes: [],
        headerData: {
            bonificacion: 0,
            listaprecio: '1',
            condcomercial: '',
            notacompra: '',
            usuario: '',
            vendedor: '',
            comision: 0,
            ingreso: (new MomentUtils()).date(new Date()),
            entrega: (new MomentUtils()).date(new Date()),
            leyenda1: '',
            leyenda2: '',
            leyenda3: '',
            leyenda4: '',
            tipoasiento: '',
            talfactura: '',
            talremito: '',
            jurisdiccion: '',
            transporte: '',
            factura: '',
            remito: '',
            pedidos: []
        },
        selectedArticulos: [],
        generatedId: '',
        importe: 0,
        confirmationDialogOpen: false,
        successDialogOpen: false,
        pdfBlob: null,
        pdfUrl: '',
        mailDialogOpen: false,
        pdfDialogOpen: false,
        dialOpen: false,
    };

    FacturasService = new FacturasService(this.context);
    MastersService = new MastersService(this.context);
    VendedoresService = new VendedoresService(this.context);
    ClientesService = new ClientesService(this.context);
    EmailService = new EmailService(this.context);

    componentDidMount() {
        this.FacturasService.getByCliente(this.props.match.params.cliente, response => this.setState({documents: response.data.result}), error => this.props.enqueueSnackbar(error, {variant: 'error'}));

        this.loadData();

        this.MastersService.getCondicionesVenta(response =>
            this.setState({condComerciales: response.data.result}), error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        if (this.context.loggedUser.role === 'A' || this.context.loggedUser.role === 'O')
            this.VendedoresService.getAll(response =>
                this.setState({vendedores: response.data.result}), error => this.props.enqueueSnackbar(error, {variant: 'error'})
            );

        this.MastersService.getTiposDeAsiento(response =>
            this.setState({tipoasientos: response.data.result}), error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
        this.MastersService.getTalonariosFactura(response =>
            this.setState({talfacturas: response.data.result}), error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
        this.MastersService.getTalonariosRemito(response =>
            this.setState({talremitos: response.data.result}), error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
        this.MastersService.getJurisdicciones(response =>
            this.setState({jurisdicciones: response.data.result}), error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
        this.MastersService.getTransportes(response =>
            this.setState({transportes: response.data.result}), error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    loadData = () => {
        this.FacturasService.getById(this.state.id, response => {
            const {...res} = response.data.result;
            const headerData = {
                bonificacion: res.bonificacion || 0,
                listaprecio: res.listaprecio || '1',
                condcomercial: res.condcomercial || '',
                notacompra: res.notacompra || '',
                usuario: res.usuario || '',
                vendedor: res.vendedor && res.vendedor > 0 ? res.vendedor : '',
                comision: res.comision || 0,
                ingreso: res.ingreso ? (new MomentUtils()).parse(res.ingreso, 'D/MM/Y') : '',
                entrega: res.entrega ? (new MomentUtils()).parse(res.entrega, 'D/MM/Y') : '',
                leyenda1: res.leyenda1 || '',
                leyenda2: res.leyenda2 || '',
                leyenda3: res.leyenda3 || '',
                leyenda4: res.leyenda4 || '',
                talfactura: res.talfactura || '',
                talremito: res.talremito || '',
                tipoasiento: res.tipoasiento || '',
                jurisdiccion: res.jurisdiccion || '',
                transporte: res.transporte || '',
                factura: res.factura || '',
                remito: res.remito || '',
                pedidos: res.pedidos || [],
                iva: res.iva || 0
            };
            this.setState({headerData});
            this.setState(prevState => {
                prevState.importe = parseFloat(res.importe);
                prevState.importeBonificado = parseFloat(res.importe_bonificado);
                return prevState;
            });

            this.ClientesService.getById(res.idcliente, response => this.setState({cliente: response.data.result}), error => this.props.enqueueSnackbar(error, {variant: 'error'}));

            this.VendedoresService.getById(res.vendedor, response => this.setState({vendedor: response.data.result}), error => this.props.enqueueSnackbar(error, {variant: 'error'}))
        }, error => this.props.enqueueSnackbar(error, {variant: 'error'}));

        this.FacturasService.getArticulosById(this.state.id, response => {
            let articulos = response.data.result;
            articulos.forEach(articulo => articulo.importe = calcImporteByArticulo(articulo.cantidad, articulo.precio, articulo.bonificacion));
            this.setState({
                selectedArticulos: articulos,
                totalArticulos: articulos.reduce((count, articulo) => (count + parseInt(articulo.cantidad)), 0),
                importeArticulos: articulos.reduce((count, articulo) => (count + parseFloat(articulo.importe)), 0)
            });
        }, error => this.props.enqueueSnackbar(error, {variant: 'error'}));
    };

    onChangeDocument = id => this.setState({id, pdfBlob: null}, () => this.loadData());

    getPDF = (fnSuccess) =>
        this.FacturasService.getPDF(
            this.state.id,
            file => fnSuccess(file),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

    render() {
        const {
            cliente,
            vendedor,
            documents,
            id,
            condComerciales,
            vendedores,
            talfacturas,
            talremitos,
            tipoasientos,
            jurisdicciones,
            transportes,
            articulos,
            headerData,
            selectedArticulos,
            generatedId,
            importe,
            importeBonificado,
            totalArticulos,
            importeArticulos,
        } = this.state;

        return (<React.Fragment>
            <Box className='niquel-facturas-view'>
                <FacturasOperation
                    operation='VIEW'
                    cliente={cliente}
                    documents={documents}
                    id={id}
                    condComerciales={condComerciales}
                    vendedores={vendedores}
                    talfacturas={talfacturas}
                    talremitos={talremitos}
                    tipoasientos={tipoasientos}
                    jurisdicciones={jurisdicciones}
                    transportes={transportes}
                    articulos={articulos}
                    headerData={headerData}
                    selectedArticulos={selectedArticulos}
                    generatedId={generatedId}
                    importe={importe}
                    totalArticulos={totalArticulos}
                    importeArticulos={importeArticulos}
                    importeBonificado={importeBonificado}
                    onChangeDocument={this.onChangeDocument}
                />
            </Box>
            <ExportOptions
                enableDownload
                enableMail
                enablePrint
                enableShow
                getPDF={this.getPDF}
                fileName={`Factura_${this.state.headerData.factura}.pdf`}
                mailTo={cliente.email}
                mailCc={vendedor.email}
                mailCCEnabled={true}
                mailSubject={`Factura ${this.state.headerData.factura}`}
                mailBody={`Estimado cliente,  \n\nSe adjunta la factura número ${this.state.headerData.factura}.`}
            />
        </React.Fragment>)
    }
}

FacturasView.contextType = MainContext;
FacturasView = withSnackbar(FacturasView);