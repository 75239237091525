import React from "react";
import ListItem from "@material-ui/core/ListItem";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import {Button, IconButton, InputAdornment, MenuItem, TextField, Tooltip, Typography} from "@material-ui/core";
import {AddRounded, AdjustRounded as Adjustcon, DeleteRounded as RemoveIcon, EditRounded as EditIcon, MoreVertRounded as MenuIcon,} from "@material-ui/icons";
import {formatPrice, InputPrice} from "assets/utils";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Menu from "@material-ui/core/Menu";
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state'
import Box from "@material-ui/core/Box";
import moment from "moment/moment";

export class CobranzaItemsList extends React.Component {
    render() {
        const {items, fechaRecibo, promedioPonderado, cliente, TIPOS, operation, fullWidth} = this.props;
        const editable = (operation === 'CREATE' || operation === 'EDIT');

        return (
            <React.Fragment>
                <Grid container justifyContent={"space-between"}>
                    <Typography
                        variant={"caption"}
                        color={items.length <= 0 ? "error" : "initial"}
                    >
                        Items de Cobranza ({items.length})
                    </Typography>
                    {!isNaN(promedioPonderado) && (
                        <Typography
                            variant={"caption"}
                            color={items.length <= 0 ? "error" : "initial"}
                        >
                            Promedio días: {promedioPonderado}
                        </Typography>
                    )}
                    <Box>
                        <Tooltip title={'Agregar Item'}>
                            <span>
                                <Button
                                    color={"primary"}
                                    variant={"outlined"}
                                    size={"small"}
                                    onClick={this.props.onAdd}
                                    disabled={!editable}
                                >
                                    Agregar
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title={'Quitar todos'}>
                            <span>
                                <IconButton
                                    onClick={this.props.onRemoveAll}
                                    disabled={!editable}
                                    className={'ml-1'}
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.54) !important'
                                    }}
                                    size={"small"}
                                >
                                    <RemoveIcon/>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                </Grid>

                <List style={{
                    maxHeight: 'calc(100vh - 21rem)',
                    overflowY: 'auto',
                    minHeight: '4.5rem'
                }}>
                    {items.map((item, index) => (
                        <>
                            <ListItem key={item.id} className={'justify-content-between align-items-baseline'} disableGutters>
                                <TextField
                                    select
                                    fullWidth={true}
                                    label={index === 0 ? "Tipo" : null}
                                    style={{width: fullWidth ? '100%' : '13rem', marginRight: fullWidth ? '1rem' : ''}}
                                    value={item.tipo}
                                    onChange={event => this.props.onChangeTipo(item.id, event.target.value)}
                                    InputProps={{
                                        readOnly: (!editable || item.observado || item.rechazado),
                                    }}
                                    error={item.rechazado || (operation !== 'CREATE' && item.tipo === 'TRP') || item.error}
                                    // helperText={
                                    //     item.rechazado ? `Ítem rechazado: ${item.observa}` :
                                    //         (operation !== 'CREATE' && item.tipo === 'TRP') ? 'Este ítem debe ser reemplazado por una transferencia definitiva.' :
                                    //             item.error ? 'Este ítem tiene campos sin completar'
                                    //                 : ''
                                    // }
                                >
                                    {TIPOS.map(option => (!editable || option.disabled !== true) && (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.description} ({option.id})
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {!item.tipo.startsWith('CH') && item.tipo !== 'SCC' && (
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            disableToolbar
                                            autoOk
                                            fullWidth
                                            label={index === 0 ? "Fecha" : null}
                                            style={{width: fullWidth ? '12rem' : '6.3rem', marginRight: fullWidth ? '1rem' : ''}}
                                            format="DD/MM/Y"
                                            minDate={editable && (item.tipo !== 'TRF' && item.tipo !== 'AEF') ? fechaRecibo.toDate() : new Date('1900-01-01')}
                                            minDateMessage={"no puede ser menor a fecha recibo"}
                                            value={item.fecha}
                                            onChange={moment => this.props.onChangeFecha(item.id, moment)}
                                            error={!moment(item.fecha).isValid() || item.rechazado}
                                            helperText={operation === 'EDIT' && item.observado ? `Orig: ${item._fecha}` : ''}
                                            readOnly={!editable || item.observado || item.rechazado}
                                            InputProps={{
                                                readOnly: (!editable || item.observado || item.rechazado),
                                                onClick: (e) => (!editable || item.observado || item.rechazado) && e.stopPropagation()
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                )}
                                <TextField
                                    label={index === 0 ? "Importe" : null}
                                    style={{width: fullWidth ? '15rem' : '8rem'}}
                                    value={item.importe}
                                    error={parseFloat(item.importe) === 0 || item.rechazado}
                                    helperText={
                                        (item.tipo === 'SCC' && editable) ?
                                            `Max. ${formatPrice(cliente.saldoCuentaCorriente, true)}` :
                                            operation === 'EDIT' && item.observado ?
                                                `Orig: ${formatPrice(item._importe, true)}` :
                                                ''
                                    }
                                    onClick={() => {
                                        if (item.tipo.startsWith('CH') && !item.observado && !item.rechazado && editable === true)
                                            this.props.onEdit(item.id);
                                    }}
                                    onChange={event => this.props.onChangeImporte(item.id, event.target.value)}
                                    onBlur={(event) => {
                                        const importe = parseFloat(item.importe);
                                        if (isNaN(importe) || importe <= 0)
                                            this.props.onChangeImporte(item.id, 0);

                                        if (item.tipo === 'SCC' && importe > cliente.saldoCuentaCorriente)
                                            this.props.onChangeImporte(item.id, cliente.saldoCuentaCorriente);
                                    }}
                                    InputProps={{
                                        readOnly: !editable || item.observado || item.rechazado || item.tipo.startsWith('CH'),
                                        inputComponent: InputPrice,
                                        style: {textAlign: 'right'},
                                        endAdornment: (
                                            <InputAdornment position="end" style={{margin: '0'}}>
                                                <Tooltip title={'Ajustar al Saldo'}>
                                                <span>
                                                    <IconButton
                                                        size={"small"}
                                                        disabled={!editable || item.observado || item.rechazado || item.tipo.startsWith('CH') || item.tipo.startsWith('R')}
                                                        onClick={() => {
                                                            this.props.onAdjustItem(item)
                                                        }}>
                                                        <Adjustcon fontSize={"small"}/>
                                                    </IconButton>
                                                </span>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {item.tipo.startsWith('CH') && (
                                    <Button variant={"outlined"} color={"primary"} size={"small"}
                                            disabled={(!editable || item.observado || item.rechazado)}
                                            onClick={() => {
                                                if (!editable || item.observado) return;
                                                this.props.onAddCheque(item.id);
                                            }}
                                    >
                                        <AddRounded fontSize={"small"}/> Cheque
                                    </Button>
                                )}
                                <PopupState variant="popover" popupId={item.id?.toString()}>
                                    {popupState => (
                                        <React.Fragment>
                                            <Tooltip title={'Opciones'}>
                                                <IconButton size={"small"} {...bindTrigger(popupState)}>
                                                    <MenuIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            <Menu keepMounted {...bindMenu(popupState)}>
                                                <MenuItem onClick={() => {
                                                    if (!editable || item.observado)
                                                        return;
                                                    popupState.close();
                                                    this.props.onRemove(item.id)
                                                }}>
                                                    <IconButton size={"small"} disabled={(!editable || item.observado)}>
                                                        <RemoveIcon/>
                                                    </IconButton>&nbsp;
                                                    Quitar
                                                </MenuItem>
                                                {this.props.onEdit && (
                                                    <MenuItem onClick={() => {
                                                        if (!editable || item.observado || item.rechazado)
                                                            return;
                                                        popupState.close();
                                                        this.props.onEdit(item.id);
                                                    }}>
                                                        <IconButton size={"small"} disabled={(!editable || item.observado || item.rechazado)}>
                                                            <EditIcon/>
                                                        </IconButton>&nbsp;
                                                        Editar
                                                    </MenuItem>
                                                )}
                                            </Menu>
                                        </React.Fragment>
                                    )}
                                </PopupState>
                            </ListItem>
                            <Typography variant={"caption"} color={"error"}>
                                {item.rechazado ?
                                    `Ítem rechazado: ${item.observa}` :
                                    (operation !== 'CREATE' && item.tipo === 'TRP') ?
                                        'Este ítem debe ser reemplazado por una transferencia definitiva.' :
                                        item.error ?
                                            'Este ítem tiene campos sin completar' :
                                            ''
                                }
                            </Typography>
                        </>
                    ))}

                    {items.length === 0 && (
                        <Typography variant={"body2"} align={"center"} color={"textSecondary"} className={'pt-4 pb-2'}>
                            Ingrese un ítem de cobranza
                        </Typography>
                    )}
                </List>
            </React.Fragment>
        )
    }
}

CobranzaItemsList.defaultProps = {
    onChangeTipo: () => {},
    onChangeFecha: () => {},
    onChangeImporte: () => {},
    onRemove: () => {},
    onRemoveAll: () => {},
    onEdit: () => {},
    onAdd: () => {},
    onAddCheque: () => {},
    onAdjustItem: () => {},
};

CobranzaItemsList.propTypes = {
    items: PropTypes.array.isRequired,
    onChangeTipo: PropTypes.func,
    onChangeFecha: PropTypes.func,
    onChangeImporte: PropTypes.func,
    onRemove: PropTypes.func,
    onEdit: PropTypes.func,
    onAdd: PropTypes.func
};
