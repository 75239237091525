import {CardChart} from "../card.chart";
import {CompradoresMensualesChart} from "../charts/compradores.mensuales.chart";
import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {useEstadisticasService} from "../../../../../services/hooks";

export const CompradoresMensualesCard = ({settingsState}) => {
    const [compradoresMensuales, setCompradoresMensuales] = useState([]);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const EstadisticasService = useEstadisticasService();

    useEffect(() => {
        EstadisticasService.getCompradoresMensuales(
            settingsState,
            success => setCompradoresMensuales(success.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }, [settingsState]);

    return (
        <CardChart
            title={'Compradores y Base de Datos'}
        >
            <CompradoresMensualesChart
                data={compradoresMensuales}
                height={300}
                value={''}
            />
        </CardChart>
    )
}
