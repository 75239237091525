import React from "react";
import Box from "@material-ui/core/Box";
import {formatPrice, formatQuantity} from "assets/utils";
import {Paper, Typography} from "@material-ui/core";
import {ResponsiveBar} from "@nivo/bar";

const moment = require('moment');

const formatId = (id, fullYear = false) => {
    let [month, anioActual = ''] = id.split('_');

    let ret = `${moment(month).format('MMM')}`;

    if (!fullYear)
        anioActual = anioActual.substr(-2);

    return `${ret} ${anioActual}`;
}

export const CompradoresMensualesChart = React.memo(function ({height, data}) {
    console.log('Render CompradoresMensuales');

    const colors = {compradores: "rgba(0, 150, 136, 0.8)", baseDeDatos: "rgba(36, 173, 160, 0.5)"};

    return (
        <Box style={{height: `${height}px`, width: '100%'}} className={'d-flex justify-content-center align-items-center'}>
            {(data.length <= 0) ?
                <Typography align={"center"} color={"textSecondary"}>
                    No hay datos para mostrar
                </Typography> :
                <ResponsiveBar
                    data={data}
                    keys={['compradores', 'baseDeDatos']}
                    indexBy={'id'}
                    margin={{top: 10, right: 40, bottom: 60, left: 80}}
                    padding={0.3}
                    groupMode="grouped"
                    valueScale={{type: 'linear'}}
                    indexScale={{type: 'band', round: true}}
                    // valueFormat={{format: '', enabled: false}}
                    colors={[colors.compradores, colors.baseDeDatos]}
                    borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Meses',
                        legendPosition: 'middle',
                        legendOffset: 40,
                        format: (id) => formatId(id)
                    }}
                    axisLeft={{
                        tickSize: 0,
                        tickPadding: 15,
                        tickRotation: 0,
                        legend: 'Cantidad',
                        legendPosition: 'middle',
                        legendOffset: -50,
                        format: '~s'
                    }}
                    enableGridY={false}
                    labelSkipWidth={20}
                    // labelTextColor={{from: 'color', modifiers: [['darker', 2.9]]}}
                    labelTextColor={"#333333"}
                    labelFormat={value => formatQuantity(value)}
                    label={d => `${formatQuantity(d.value)}`}
                    isInteractive={true}
                    tooltip={(props) => {
                        return (
                            <Paper elevation={1} className={'p-2'}>
                                <Typography variant={"body2"} style={{color: colors[props.id]}}  component={"span"}>
                                    <b>{props.id === "baseDeDatos" ? "Base de Datos" : "Compradores"}: </b>
                                </Typography>
                                <Typography variant={"body2"} component={"span"}>
                                    <b>{formatQuantity(props.value)}</b>
                                </Typography>
                                <Typography variant={"body2"} className={'mt-2'} align={"left"}>
                                    {formatId(props.indexValue, true)}
                                </Typography>
                            </Paper>
                        )
                    }}
                    pointSize={10}
                    pointColor={{theme: 'background'}}
                    pointBorderWidth={2}
                    pointBorderColor={{from: 'serieColor'}}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                />
            }
        </Box>
    )
})
