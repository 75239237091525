import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {TableContainer, Typography} from "@material-ui/core";
import {formatPrice} from "assets/utils";
import Box from "@material-ui/core/Box";

export const RankingTable = React.memo(function ({detailTitle, detailField, height, data, id, value}) {
    console.log('Render RankingTable');

    const key = value.id;
    data.sort((a, b) => (parseFloat(b[key]) - parseFloat(a[key])));

    return (
        (data.length <= 0) ?
            <Box style={{height: '25rem'}}>
                <Typography align={"center"} color={"textSecondary"}>
                    No hay datos para mostrar
                </Typography>
            </Box> :
            <TableContainer style={{height: '25rem'}}>
                <Table size="small" stickyHeader aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Pos</TableCell>
                            {id === 'codigo' && ( // solo articulos
                                <TableCell align="left">Código</TableCell>
                            )}
                            <TableCell align="left">Detalle</TableCell>
                            {value.id === 'cantidad' && (
                                <TableCell align="left">Cantidad</TableCell>
                            )}
                            {value.id === 'importe' && (
                                <TableCell align="left">Importe</TableCell>
                            )}
                            <TableCell align="right">Porc.</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row" align={"center"}>{index + 1}</TableCell>
                                {id === 'codigo' && ( // solo articulos
                                    <TableCell align="left" style={{wordBreak: 'break-all'}}>{row.id}</TableCell>
                                )}
                                <TableCell align="left" style={{wordBreak: 'break-all'}}>{row.detalle}</TableCell>
                                {value.id === 'cantidad' && (
                                    <>
                                        <TableCell align="right">{row.cantidad}</TableCell>
                                        <TableCell align="right">{(row.porc_cant * 100).toFixed(2)}%</TableCell>
                                    </>
                                )}
                                {value.id === 'importe' && (
                                    <>
                                        <TableCell align="right">{formatPrice(row.importe)}</TableCell>
                                        <TableCell align="right">{(row.porc_importe * 100).toFixed(2)}%</TableCell>
                                    </>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
    )
})
