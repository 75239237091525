import {Paper, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React from "react";

export const CardChart = ({title, subtitle = '', children, rightContent}) => {
    return (
        <Paper>
            <Box className={'d-flex align-items-center justify-content-between'}>
                <Box className={'pt-3 pb-3 pl-4'}>
                    <Typography variant={"body1"} color={"textSecondary"}>{title}</Typography>
                    <Typography variant={"body2"} color={"textSecondary"}>{subtitle}</Typography>
                </Box>
                <Box className={'pr-4'}>
                    {rightContent}
                </Box>
            </Box>
            {children}
        </Paper>
    )
}
