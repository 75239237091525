import React from "react";
import {ResponsivePie} from "@nivo/pie";
import Box from "@material-ui/core/Box";
import {Paper, Typography} from "@material-ui/core";
import {formatPrice} from "assets/utils";

const VendedoresTooltip = (key, datum) => {
    const _data = key === 'porc_importe' ? formatPrice(datum.data['importe'], true) : `${datum.data['cantidad']} ventas`;
    return <Paper className={'p-2'}>{datum.id}: <b>{_data}</b> <span style={{color: datum.color}}><b>({datum.formattedValue})</b></span></Paper>;
}

export const RankingVendedoresChart = React.memo(function ({height, data, id, value}) {
    console.log('Render RankingVendedores');

    // Clone and sort array
    let _data = [...data];
    _data = _data.sort((a,b) => b[value] - a[value]);

    return (
        <Box style={{height: `${height}px`, width: '100%'}} className={'d-flex justify-content-center align-items-center'}>
            {data.length <= 0 ?
                <Typography align={"center"} color={"textSecondary"}>
                    No hay datos para mostrar
                </Typography> :
                <ResponsivePie
                    data={_data}
                    id={id}
                    value={value}
                    valueFormat=".2%"
                    margin={{ top: 10, right: 170, bottom: 10, left: 170}}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    colors={{ scheme: 'nivo' }}
                    borderColor={{ from: 'color', modifiers: [ [ 'darker', '0' ] ] }}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
                    arcLabelsSkipAngle={10}
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    tooltip={(e) => VendedoresTooltip(value, e.datum)}
                />
            }
        </Box>
    )
})
