import {Card, Grid, IconButton, Tooltip, Typography} from "@material-ui/core";
import {DeleteRounded, PhotoCameraRounded, PhotoRounded} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useCobranzasService} from "services/hooks";
import {useSnackbar} from "notistack";
import {SimpleDialog} from "../dialogs/simple.dialog";
import {detectMimeType} from "../../../assets/utils";

export const AdditionalData = ({idrecibo, items, editMode}) => {
    items = items ? [...items] : [];

    const item_recibo = {
        tipo: "",
        leyenda: "Recibo de Cobranza",
        idrecibo: idrecibo,
        numitem: "RDC"
    };

    items = items.map(item => ({...item, idrecibo: idrecibo, numitem: item.id}));

    return (
        <Card className={'pt-2 pb-2 pr-3 pl-3 h-100'}>
            <Grid container direction={"column"}>
                <DataItem item={item_recibo} editMode={editMode}/>
                {items
                    .filter(item => item.tipo.startsWith("R"))
                    .map((item, index) => (
                        <DataItem item={item} editMode={editMode}/>
                    ))
                }
            </Grid>
        </Card>
    )
}

const DataItem = ({item, editMode}) => {
    const [uploaded, setUploaded] = useState(false);

    const CobranzasService = useCobranzasService();

    useEffect(() => {
        CobranzasService.getComprobante(
            item.idrecibo,
            item.numitem,
            image => {
                setUploaded(uploaded || !!image)
            },
            error => {
            }
        );
    }, [item.idrecibo, uploaded]);

    return (
        <>
            <Grid item container justifyContent={"space-between"} alignItems={"center"}>
                <Grid item>
                    <Typography variant={"body2"}>{item.leyenda}</Typography>
                </Grid>
                <div style={{
                    flex: '1 1 auto',
                    borderBottom: '1px solid #e4e4e4',
                    margin: '0 0.3rem -0.8rem 0.3rem'
                }}/>
                <Grid item>
                    <Uploader item={item} setUploaded={setUploaded} editMode={editMode}>
                        <Tooltip title={"Subir una imágen"}>
                            <IconButton component="span" disabled={editMode === false}><PhotoCameraRounded/></IconButton>
                        </Tooltip>
                    </Uploader>
                    {uploaded && (
                        <Viewer item={item}>
                            <Tooltip title={"Ver imágen subida"}>
                                <IconButton><PhotoRounded color={"primary"}/></IconButton>
                            </Tooltip>
                        </Viewer>
                    )}
                    {uploaded && (
                        <Deleter item={item} setUploaded={setUploaded} editMode={editMode}>
                            <Tooltip title={"Borrar imágen subida"}>
                                <IconButton disabled={editMode === false}><DeleteRounded color={editMode ? "error" : ""}/></IconButton>
                            </Tooltip>
                        </Deleter>
                    )}
                </Grid>
            </Grid>
            {uploaded === false && (
                <Typography variant={"caption"} color={"error"} style={{marginTop: '-0.7rem'}}>Debe subir una imágen</Typography>
            )}
        </>
    )
}

const Uploader = ({children, item, setUploaded, editMode}) => {
    const [loading, setLoading] = useState(false);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const CobranzasService = useCobranzasService();

    const uploadComprobante = event => {
        if (editMode === false) return false;

        setLoading(true);

        // Paso los parámetros a un formData para poder enviar el archivo (remito)
        var formData = new FormData();
        formData.append('comprobante', event.target.files[0]);
        // Limpio el input por si quieren subir varias veces.
        event.target.value = '';

        CobranzasService.uploadComprobante(
            item.idrecibo,
            item.numitem,
            formData,
            success => {
                setLoading(false);
                setUploaded(true);
            },
            error => {
                setLoading(false);
                enqueueSnackbar(error, {variant: 'error'})
            }
        );
    }

    return (
        <span className={'position-relative'}>
            <input accept="image/*" className={'d-none'} id={`${item.idrecibo}-${item.numitem}-file`} type="file" disabled={editMode === false} onChange={uploadComprobante}/>
            <label htmlFor={`${item.idrecibo}-${item.numitem}-file`}>{children}</label>
            {loading && <CircularProgress size={45} style={{
                color: 'rgba(0, 121, 107, 0.8)',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -22.5,
                marginLeft: -22.5
            }}/>}
        </span>
    )
}

const Viewer = ({children, item}) => {
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [popupOpen, setPopupOpen] = useState(false);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const CobranzasService = useCobranzasService();

    const viewComprobante = event => {
        setLoading(true);

        CobranzasService.getComprobante(
            item.idrecibo,
            item.numitem,
            image => {
                setLoading(false);
                setImage(image);
                setPopupOpen(true);
            },
            error => {
                setLoading(false);
                enqueueSnackbar(error, {variant: 'error'})
            }
        );
    }

    return (
        <>
        <span className={'position-relative'} onClick={viewComprobante}>
            {children}
            {loading && <CircularProgress size={45} style={{
                color: 'rgba(0, 121, 107, 0.8)',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -22.5,
                marginLeft: -22.5
            }}/>}
        </span>
            <SimpleDialog
                title={`item-${item.numitem}`}
                open={popupOpen}
                body={
                    detectMimeType(image) === "pdf" ?
                        <embed src={`data:application/pdf;base64,${image}#toolbar=1&navpanes=0&scrollbar=1`} width={'100%'} height={'600px'} type={'application/pdf'}/> :
                        <img src={`data:image/jpeg;base64,${image}`} alt={`item-${item.numitem}`} style={{maxHeight: '100%', maxWidth: '100%'}}/>
                }
                fullWidth={true}
                maxWidth={'md'}
                onCancel={() => setPopupOpen(false)}
            />
        </>
    )
}

const Deleter = ({children, item, setUploaded, editMode}) => {
    const [loading, setLoading] = useState(false);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const CobranzasService = useCobranzasService();

    const deleteComprobante = event => {
        if (editMode === false) return false;

        setLoading(true);

        CobranzasService.deleteComprobante(
            item.idrecibo,
            item.numitem,
            success => {
                setLoading(false);
                setUploaded(false);
            },
            error => {
                setLoading(false);
                enqueueSnackbar(error, {variant: 'error'})
            }
        );
    }

    return (
        <span className={'position-relative'} onClick={deleteComprobante}>
            {children}
            {loading && <CircularProgress size={45} style={{
                color: 'rgba(0, 121, 107, 0.8)',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -22.5,
                marginLeft: -22.5
            }}/>}
        </span>
    )
}