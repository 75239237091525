import React, {useEffect, useReducer, useState} from 'react'
import {useEmailService, useEstadisticasService, useListadosService} from "services/hooks";
import {generalReducer} from "components/helpers/reducers/reducers";
import {INITIAL_FILTER_SETTINGS_STATE} from "components/helpers/initialFilterSettingsState";
import {useSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";
import {CardChart} from "components/estadisticas/dashboards/ventas/card.chart";
import {CompradoVendidoTable} from "components/stock/compradoVendido/compradoVendido.table";
import {Box, Fab, MenuItem, TextField, Tooltip} from "@material-ui/core";
import {SettingsRounded} from "@material-ui/icons";
import SubHeader from "components/helpers/subHeader/subHeader";
import VentasSettings from "components/estadisticas/settings/ventas.settings";
import {MailDialog} from "components/helpers/dialogs/mail.dialog";
import {PDFDialog} from "components/helpers/dialogs/pdf.dialog";
import SpeedDial from "@material-ui/lab/SpeedDial/SpeedDial";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction/SpeedDialAction";
import printJS from "print-js";
import EmailIcon from "@material-ui/icons/EmailRounded";
import PrintIcon from "@material-ui/icons/PrintRounded";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import ShowIcon from "@material-ui/icons/DesktopWindowsRounded";

const FUENTES = [{id: 'REMITOS', description: 'Remitos'}, {id: 'FACTURAS', description: 'Facturación'}];

export function CompradoVendido() {
    const [_settingsState, dispatch] = useReducer(generalReducer, INITIAL_FILTER_SETTINGS_STATE);
    const [settingsState, setSettingsState] = useState(INITIAL_FILTER_SETTINGS_STATE);
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
    const [compraVentaArticulos, setCompraVentaArticulos] = useState(null);

    const [fuente, setFuente] = useState('REMITOS');

    const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
    const [dialOpen, setDialOpen] = useState(false);
    const [mailDialogOpen, setMailDialogOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

    const EstadisticasService = useEstadisticasService();
    const EmailService = useEmailService();
    const ListadosService = useListadosService();

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    useEffect(() => {
        EstadisticasService.getCompraVentaArticulos(
            settingsState,
            fuente,
            success => setCompraVentaArticulos(success.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }, [settingsState, fuente]);

    const onConfirmSettingsDialog = () => {
        setSettingsState(_settingsState);
        setSettingsDialogOpen(false);
    }
    const onCancelSettingsDialog = () => {
        dispatch({payload: settingsState, type: 'fullstatechange'});
        setSettingsDialogOpen(false);
    }

    const getPDF = fnSuccess => {
        ListadosService.compraVentaArticulos(
            settingsState,
            fuente,
            file => fnSuccess(file),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    };

    // Show PDF
    const onOpenPdfDialog = () => setPdfDialogOpen(true);
    const onClosePdfDialog = () => setPdfDialogOpen(false);

    const onShowPDF = () => {
        getPDF(
            (blob) => {
                const fileURL = URL.createObjectURL(blob);
                setPdfUrl(fileURL);
                onOpenPdfDialog();
            }
        );
    };

    // Print PDF
    const onPrintPDF = () => {
        getPDF(
            (blob) => {
                const fileURL = URL.createObjectURL(blob);
                printJS(fileURL);
            }
        );
    };

    // Download PDF
    const onDownloadPdf = () => {
        getPDF(
            (blob) => {
                const fileURL = URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = `Comprado Vendido`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                enqueueSnackbar('El archivo se descargó correctamente. Revise su carpeta de descargas.', {variant: 'success'});
            }
        );
    };

    // Email
    const onOpenMailDialog = () => setMailDialogOpen(true);

    const dialActions = [
        {icon: <EmailIcon/>, name: 'Enviar por Email', action: onOpenMailDialog},
        {icon: <PrintIcon/>, name: 'Imprimir', action: onPrintPDF},
        {icon: <DownloadIcon/>, name: 'Descargar', action: onDownloadPdf},
        {icon: <ShowIcon/>, name: 'Ver en Pantalla', action: onShowPDF},
    ];

    const onCloseMailDialog = () => setMailDialogOpen(false);

    const sendMail = mailData => {
        let sendMail = (blob) => {
            // Paso los parámetros a un formData para poder enviar el blob (attachment)
            var formData = new FormData();
            formData.append('attachment', blob);
            formData.append('data', JSON.stringify(mailData));

            EmailService.send(
                formData,
                response => {
                    onCloseMailDialog();
                    enqueueSnackbar('El email se envió correctamente', {variant: 'success'});
                },
                error => enqueueSnackbar(error, {variant: 'error'})
            );
        };

        if (mailData.attachFile)
            getPDF((blob) => sendMail(blob));
        else
            sendMail();
    };

    const onDialOpen = () => setDialOpen(true);
    const onDialClose = () => setDialOpen(false)

    return (
        <>
            <Box className='niquel-estadisticas-main'>
                <SubHeader
                    title={'Comprado/Vendido'}
                    // subtitle={'Volúmenes comprados y vendidos de artículos'}
                />
                <Box className='container mt-3 mb-3'>
                    <Box className={'mt-3'}>
                        <Grid container spacing={1}>
                            <Grid item sm={12}>
                                <CardChart
                                    title={'Compras y ventas de Artículos'}
                                    subtitle={'por volúmen en litros o unidades según corresponda'}
                                    rightContent={
                                        <TextField
                                            select
                                            label="Fuente"
                                            value={fuente}
                                            onChange={event => setFuente(event.target.value)}
                                        >
                                            {FUENTES.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.description}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    }
                                >
                                    <CompradoVendidoTable
                                        data={compraVentaArticulos}
                                        // height={400}
                                    />
                                </CardChart>
                            </Grid>
                        </Grid>
                    </Box>
                    <Tooltip title={'Configurar filtros'}>
                        <Box className={'niquel-fab-top-btn'}>
                            <Fab onClick={() => setSettingsDialogOpen(true)} color={"default"}>
                                <SettingsRounded/>
                            </Fab>
                        </Box>
                    </Tooltip>
                </Box>
                <Box className='niquel-fab-btn'>
                    <SpeedDial
                        ariaLabel="comprado_vendido"
                        icon={<PDFIcon/>}
                        onBlur={onDialClose}
                        onClick={onDialOpen}
                        onClose={onDialClose}
                        onFocus={onDialOpen}
                        onMouseEnter={onDialOpen}
                        onMouseLeave={onDialClose}
                        open={dialOpen}
                    >
                        {dialActions.map(action => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={(e) => {
                                    e.preventDefault();
                                    action.action()
                                }}
                            />
                        ))}
                    </SpeedDial>
                </Box>
            </Box>
            <VentasSettings
                open={settingsDialogOpen}
                title={'Filtros y confirguración'}
                onCancel={onCancelSettingsDialog}
                onConfirm={onConfirmSettingsDialog}
                fullScreen={true}
                maxWidth={'lg'}
                state={_settingsState}
                dispatch={dispatch}
            />
            <MailDialog
                open={mailDialogOpen}
                onConfirm={sendMail}
                onCancel={onCloseMailDialog}
                fileName={`Comprado_Vendido.pdf`}
                attachFile={true}
                // to={cliente.email}
                subject={`Comprado Vendido`}
                body={`Estimado,  \n\nSe adjunta el listado de compra y venta de artículos.`}
            />
            <PDFDialog
                open={pdfDialogOpen}
                onClose={onClosePdfDialog}
                pdfUrl={pdfUrl}
                pdfName={`Comprado_Vendido.pdf`}
            />
        </>
    )
}
