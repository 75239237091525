import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {ClientesInhabilitados} from "./clientesInhabilitados/clientesInhabilitados";
import {DocumentosImpagos} from "./documentosImpagos/documentosImpagos";
import {PedidosPendientes} from "./pedidosPendientes/pedidosPendientes";
import {EstadisticasVentas} from "./estadisticasVentas/estadisticasVentas";
import {CuentasCorrientes} from "./cuentasCorrientes/cuentasCorrientes";
import {CobranzasTransitorias} from "./cobranzasTransitorias/cobranzasTransitorias";
import {RendicionCobranzas} from "./rendicionCobranzas/rendicionCobranzas";
import {NotasCreditoAtrasadas} from "./notasCreditoAtrasadas/notasCreditoAtrasadas";
import {MainContext} from "contexts/main.context";

const basePath = '/listados';

export class Listados extends Component {
    componentDidMount() {
        this.context.setHeaderTitle('Listados');
    }

    render() {
        return (
            <div className='niquel-listados'>
                <Switch>
                    <Route path={`${basePath}/clientesInhabilitados`} component={ClientesInhabilitados}/>
                    <Route path={`${basePath}/documentosImpagos`} component={DocumentosImpagos}/>
                    <Route path={`${basePath}/pedidosPendientes`} component={PedidosPendientes}/>
                    <Route path={`${basePath}/estadisticasVentas`} component={EstadisticasVentas}/>
                    <Route path={`${basePath}/cuentasCorrientes`} component={CuentasCorrientes}/>
                    <Route path={`${basePath}/cobranzasTransitorias`} component={CobranzasTransitorias}/>
                    <Route path={`${basePath}/rendicionCobranzas`} component={RendicionCobranzas}/>
                    <Route path={`${basePath}/notasCreditoAtrasadas`} component={NotasCreditoAtrasadas}/>
                </Switch>
            </div>
        )
    }
}

Listados.contextType = MainContext;
