import BaseService from './base.service'

export default class EstadisticasService extends BaseService {
    getVentasMensuales(settings, fnSuccess, fnError) {
        return this.getApi().post(`/estadisticas/ventas/mensuales`, settings)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getRankingArticulos(settings, fnSuccess, fnError) {
        return this.getApi().post(`/estadisticas/ventas/ranking/articulos`, settings)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getRankingVendedores(settings, fnSuccess, fnError) {
        return this.getApi().post(`/estadisticas/ventas/ranking/vendedores`, settings)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getRankingProveedores(settings, fnSuccess, fnError) {
        return this.getApi().post(`/estadisticas/ventas/ranking/proveedores`, settings)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getRankingClientes(settings, fnSuccess, fnError) {
        return this.getApi().post(`/estadisticas/ventas/ranking/clientes`, settings)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getTotalesPorPeriodo(settings, fnSuccess, fnError) {
        return this.getApi().post(`/estadisticas/ventas/totalesPorPeriodo`, settings)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getCompraVentaArticulos(settings, fuente, fnSuccess, fnError) {
        return this.getApi().post(`/estadisticas/ventas/compraVenta?fuente=${fuente}`, settings)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getCompradoresMensuales(settings, fnSuccess, fnError) {
        return this.getApi().post(`/estadisticas/ventas/compradoresMensuales`, settings)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }
}
